import { Container, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Carousel from "./Carousel";

const useStyles = makeStyles(() => ({
  banner: {
    // backgroundImage: "url('./bannerimg.jpg')",

    backgroundSize: "cover",
    backgroundOpacity: "0.2",
  },
  "@media (max-width:780px)": {
    banner: {
      paddingTop: "3%",
    },
  },
  bannerContent: {
    height: 400,
    display: "flex",
    flexDirection: "column",
    paddingTop: 25,
    justifyContent: "space-around",
  },
  tagline: {
    // fontFamily: " 'Montserrat', sans-serif",
    display: "flex",
    height: "40%",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  taglineTitle: {
    fontWeight: "bold",
  },
}));

const Banner = ({ lastupdate }) => {
  const classes = useStyles();
  return (
    <div className={classes.banner}>
      <Container className={classes.bannerContent}>
        <div className={classes.tagline}>
          <Typography variant="h3" className={classes.taglineTitle}>
            Currency Exchange Rates
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              color: "darkgrey",
              textTransform: "capitalize",
              marginTop: "10px",
            }}
          >
            {"Last Update: " + lastupdate.toString() + " (GMT+5:30)"}
          </Typography>
        </div>
        <Carousel />
      </Container>
    </div>
  );
};

export default Banner;
