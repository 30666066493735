import React, { useEffect, useState } from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import axios from "axios";
import { bannerCurrencies, TrendingCoins } from "../config/api";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { numberWithCommas } from "./CurrencyTable";

const useStyles = makeStyles((theme) => ({
  carousel: {
    height: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  carouselItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    textTransform: "uppercase",
    color: "white",
  },
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const responsive = {
  0: { items: 2 },
  512: { items: 3 },
  768: { items: 4 },
};

const Carousel = () => {
  const [bannerCurrencies, setBannerCurrencies] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  //fetching the banner currencies
  const fetchBannerCoins = async () => {
    setLoading(true);

    const { data } = await axios.post(TrendingCoins(), {
      token: localStorage.getItem("temitope"),
    });
    setBannerCurrencies(data);
    setLoading(false);
  };

  //useeffect to fetch the banner currencies
  useEffect(() => {
    fetchBannerCoins();
  }, []);

  //carousel items
  const items = bannerCurrencies.map((coin) => {
    return (
      <div className={classes.carouselItem}>
        <img
          src={coin?.image}
          alt={coin.currency}
          height="80"
          style={{ marginBottom: 10 }}
        />

        <span
          style={{
            color: "rgb(14, 203, 129)",
            fontWeight: 500,
          }}
        >
          {coin?.currency}
        </span>

        <span style={{ fontSize: 22, fontWeight: 500 }}>
          LKR {numberWithCommas(coin?.selling)}
        </span>
      </div>
    );
  });

  return (
    <div className={classes.carousel}>
      {loading ? (
        <CircularProgress color="gold" className={classes.loader} />
      ) : (
        <AliceCarousel
          mouseTracking
          infinite
          autoPlay={10000}
          dots={false}
          disableDotsControls
          disableButtonsControls
          buttonsDisabled={true}
          responsive={responsive}
          items={items}
        />
      )}
    </div>
  );
};

export default Carousel;
