import { Divider, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  footer: {
    display: "flex",
    bottom: 0,
    height: "25vh",
    left: 0,
    bottom: 0,
    right: 0,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#141617",
    color: "#D3D3D3",
    fontFamily: "Verdana",
    fontSize: "35px",
  },
  img: {
    paddingTop: "10px",

    height: "100px",
  },
  divider: {
    color: "white",
  },
  titile: {
    fontFamily: "Arial",

    fontSize: "17px",
    fontWeight: "bolder",
  },
}));
const Footer = ({footer}) => {
  const classes = useStyles();
  return (
    <div>
      <Divider className={classes.divider} />
      <footer className={classes.footer}  style={footer}>
        <div className={classes.title}>Powered By</div>
        <img className={classes.img} src="/img/logo2.png" alt="" />
      </footer>
    </div>
  );
};

export default Footer;
