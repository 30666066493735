// import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
import CurrencyPage from "./Pages/CurrencyPage";
import Home from "./Pages/Home";
import { makeStyles } from "@material-ui/core";
import "./App.css";
import Login from "./Pages/Login";
import { RequireToken } from "./config/auth";
import { useState } from "react";
import Footer from "./Components/Footer";

const useStyles = makeStyles(() => ({
  App: {
    backgroundColor: "#14161a",
    color: "white",
    minHeight: "100vh",
    
  },
}));

function App() {
  const [header, setHeader] = useState();
  const [footer, setFooter] = useState();
  const classes = useStyles();

  return (
    <BrowserRouter>
      <div className={classes.App}>
        <Header header={header} />
        <Routes>
          <Route
            path="/"
            element={
              <RequireToken>
                <Home setHeader={setHeader} setFooter={setFooter} />
              </RequireToken>
            }
          />
          <Route path="/login" element={<Login setHeader={setHeader} setFooter={setFooter}  />} />
          <Route
            path="/coins/:id"
            element={
              <RequireToken>
                <CurrencyPage />
              </RequireToken>
            }
          />
        </Routes>
      <Footer footer={footer}/>

      </div>
    </BrowserRouter>
  );
}

export default App;
