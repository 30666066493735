import { Button, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Banner from "../Components/Banner";
import CurrencyConverter from "../Components/CurrencyConverter";
import CurrencyTable from "../Components/CurrencyTable";
import TelegramIcon from '@material-ui/icons/Telegram';
import { Mail, Phone } from "@material-ui/icons";
import TawkTo from "tawkto-react";

const useStyles = makeStyles(() => ({
  item: {
    fontSize: "12px",
    // fontFamily: "Montserrat",
  },
  title: {
    fontSize: "27px",
    fontWeight: "bold",
  },
  header: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: "gold",
  },
  buttonLink: {
    color: "white",
  },
  button: {
    
    backgroundColor: "#14171f !important",
    border: "0.5px solid white !important",
  },
  "@media (max-width:780px)": {
    buttonText:
    {
      display: "none",
    }
  }
}));

const Home = ({ setHeader,setFooter }) => {
  
  const classes = useStyles();
  const [lastupdate, setLastupdate] = useState(new Date());

  useEffect(() => {
    var tawk = new TawkTo("6290b876b0d10b6f3e745518", "default")
    setFooter({position:"relative"});
    setHeader(
      <div className={classes.header}>
        <Typography variant="h4" className={classes.title}>
        BITLEONEX
        </Typography>
        <div>
          <Button className={classes.button} color="primary">
            <a
            style={{display:"flex"}}
              color="primary"
              target="_blank"
              href="https://t.me/Skylark21"
              className={classes.buttonLink}
              >
                <TelegramIcon/> <span className={classes.buttonText}>&nbsp;TELEGRAM</span>
            </a>
          </Button>&nbsp;&nbsp;
          <Button className={classes.button} color="primary">
            <a style={{display:"flex"}}
              color="primary"
              href="mailto:leoglarrixenterprises@gmail.com"
              className={classes.buttonLink}
              >{" "}
              <Mail/>
              <span className={classes.buttonText}>&nbsp;E-mail</span>
            </a>
          </Button>
        </div>
      </div>
    );
  }, [setHeader]);
  return (
    <div>
      <Banner lastupdate={lastupdate} />

      <CurrencyTable setLastupdate={setLastupdate} />

      <CurrencyConverter />
    </div>
  );
};

export default Home;
