import CurrencyInput from "./CurrencyInput";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  FormLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Converter } from "../config/api";



const useStyles = makeStyles(() => ({
  currencyDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px",
    margin: "0 auto",
    paddingBottom: "100px",
  },
  currencyConverter: {
    marginTop: "50px",
    marginBottom: "30px",
    margin: "0 auto",
  },
  select: {
    "&:before": {
      borderColor: "white",
      color: "white !important",
    },
    "&:after": {
      borderColor: "white",
      color: "white !important",
    },
  },

  icon: {
    fill: "white",
  },
}));

function titleCase(str) {
  return str
      .split(' ')
      .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
}

const CurrencyConverter = () => {
  const [amount1, setAmount1] = useState(1);
  const [amount2, setAmount2] = useState(1);
  const [currency1, setCurrency1] = useState("USD");
  const [currency2, setCurrency2] = useState("LKR");
  const [allRates, setAllRates] = useState([]);

  const [rates, setRates] = useState([]);
  const [type, setType] = useState("buying");
  useEffect(() => {
    axios
      .post(Converter(), {
        token: localStorage.getItem("temitope"),
      })
      .then((response) => {
        setAllRates(response.data.rates);
        setRates(response.data.rates.buying);
      });
  }, []);

  useEffect(() => {
    if (!!rates) {
      function init() {
        handleAmount1Change(1);
      }
      init();
    }
  }, [rates]);

  function roundUp(number) {
    return number.toFixed(2);
  }

  function handleAmount1Change(amount1) {
    setAmount2(roundUp(amount1 * rates[currency2] * rates[currency1]));
    setAmount1(amount1);
  }

  function handleCurrency1Change(currency1) {
    setAmount2(roundUp(amount1 * rates[currency2] * rates[currency1]));
    setCurrency1(currency1);
  }

  function handleAmount2Change(amount2) {
    setAmount1(roundUp((amount2 * rates[currency2]) / rates[currency1]));
    setAmount2(amount2);
  }

  function handleCurrency2Change(currency2) {
    setAmount1(roundUp((amount2 * rates[currency2]) / rates[currency1]));
    setCurrency2(currency2);
  }

  const switchType = (event) => {
    setType(event.target.value);
    setRates(
      event.target.value.toLowerCase() == "selling"
        ? allRates.selling
        : allRates.buying
    );
  };
  const classes = useStyles();
  return (
    <Container>
      <section className={classes.currencyDiv}>
        <div className="input-div">
          <main className="currency-converter-main"></main>
          <Typography className={classes.currencyConverter} variant="h5">
            Currency Converter &nbsp;
            <Select
              inputProps={{
                classes: {
                  icon: classes.icon,
                  root: classes.root,
                },
              }}
              id="type"
              className={classes.select}
              size="large"
              value={type}
              color="secondary"
              onChange={(event) => switchType(event)}
            >
              <MenuItem value="buying">Buying</MenuItem>
              <MenuItem value="selling">Selling</MenuItem>
            </Select>
          </Typography>
          <CurrencyInput
            onAmountChange={handleAmount1Change}
            onCurrencyChange={handleCurrency1Change}
            currencies={Object.keys(rates)}
            amount={amount1}
            currency={currency1}
            disabled={false}
          />
          <FormLabel
            style={{
              display: "inline-block",
              margin: "auto",
              color: "white",
              paddingBottom: "17px",
              paddingLeft: "21.5%",
            }}
          >
            {titleCase(type)} Rate: 1 {currency1} = {rates[currency1]} LKR
            
          </FormLabel>
          <CurrencyInput
            onAmountChange={handleAmount2Change}
            onCurrencyChange={handleCurrency2Change}
            currencies={Object.keys(rates)}
            amount={amount2}
            currency={currency2}
            disabled={true}
          />
        </div>
      </section>
    </Container>
  );
};

export default CurrencyConverter;
