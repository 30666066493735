import {
  Button,
  Container,
  createTheme,
  makeStyles,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { loginApi } from "../config/api";
import { fetchToken, setToken } from "../config/auth";

const useStyles = makeStyles(() => ({
  footer: {
    position: "absolute",
    display: "flex",
    bottom: 0,
    height: "21vh",
    left: 0,
    bottom: 0,
    right: 0,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#141617",
    color: "#D3D3D3",
    fontFamily: "Verdana",
    fontSize: "35px",
  },
  loginContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "10%",
    paddingLeft: "1%",
    paddingRight: "1%",
  },

  "@media (max-width:780px)": {
    loginContainer: {
      paddingTop: "25%",
    },
  },
  multilineColor: {
    color: "red",
  },
  loginForm: {
    // paddingTop:"300px",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#36393F",
    color: "white",
    maxWidth: "400px",
    maxHeight: "400px",
    padding: "20px",
    borderRadius: "5px",
  },
  headerButton: {
    color: "white !important",
  },
  button: {
    backgroundColor: "#EDD94C !important",
    marginTop: "20px",
    color: "black",
    width: "100%",
  },

  title1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "35px",
    fontWeight: "bold",
  },
  title2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "25px",
    fontWeight: "bold",
  },
  header: {
    flex: 1,
    color: "gold",
  },
}));

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
    type: "dark",
  },
});

export default function Login({ setHeader, setFooter }) {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [buttonstate, setButtonstate] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setFooter({ position: "absolute" });
    setHeader(
      <div className={classes.header}>
        <Typography variant="h4" className={classes.title1}>
          BITLEONEX
        </Typography>
        {/* <Button className={classes.headerButton}>Authenticate</Button> */}
      </div>
    );
  }, [setHeader, setHeader]);
  //check to see if the fields are not empty
  const login = () => {
    if (username == "") {
      return;
    } else {
      setButtonstate(true);
      axios
        .post(loginApi(), {
          username: username,
        })
        .then(function (response) {
          setButtonstate(false);
          if (response.data.token) {
            setToken(response.data.token);
            navigate("/");
          }
        })
        .catch(function (error) {
          setButtonstate(false);
        });
    }
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      login();
    }
  };
  return (
    <ThemeProvider theme={darkTheme}>
      <div className={classes.loginContainer}>
        <Container className={classes.loginForm}>
          <Typography className={classes.title2}>
            Enter Code to Authenticate
          </Typography>

          {fetchToken() ? (
            navigate("/")
          ) : (
            <div>
              <form className={classes.loginForm} action="javascript:void(0)">
                <TextField
                  color="primary"
                  type="password"
                  label="Enter Code"
                  size="small"
                  variant="outlined"
                  onFocus={()=>{
                    if (window.innerWidth < 960) {
                      setFooter({ display: "none" })
                    }
                  }}
                  onBlur={()=>{setFooter({ position: "fixed" })}}
                  onChange={(e) => setUsername(e.target.value)}
                  onKeyDown={_handleKeyDown}
                />

                <Button
                  className={classes.button}
                  variant="contained"
                  type="button"
                  onClick={login}
                  disabled={buttonstate}
                >
                  Authenticate
                </Button>
              </form>
            </div>
          )}
        </Container>
      </div>
    </ThemeProvider>
  );
}
