import {
  Container,
  createTheme,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CoinList } from "../config/api";

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
    type: "dark",
  },
});

const useStyles = makeStyles(() => ({
  table: {
    marginTop: "50px",
  },
  row: {
    backgroundColor: "#16171a",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#131111",
    },
    // fontFamily: "Montserrat",
  },
}));

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const CurrencyTable = ({setLastupdate}) => {
  const navigate = useNavigate();
  const [currencies, setCurrencies] = useState();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const fetchCurrencies = async () => {
    setLoading(true);
    const { data } = await axios.post(CoinList(), {
      token: localStorage.getItem("temitope")
    });
    if(data.hasOwnProperty ("message")){
      localStorage.clear();
      window.location.href="/login";
    }

    setCurrencies(data.rates);
    setLastupdate(data.updated);
    setLoading(false);
  };

  useEffect(() => {
    fetchCurrencies();
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <Container style={{ textAlign: "center" }}>
        <TableContainer className={classes.table} component={Paper}>
          {loading ? (
            <LinearProgress style={{ backgroundColor: "gold" }} />
          ) : (
            <Table aria-label="simple table">
              <TableHead style={{ backgroundColor: "#EEBC1D" }}>
                <TableRow>
                  {["Currency", "Buying Price","Selling Price"].map((head) => (
                    <TableCell
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontWeight: "750",
                        // fontFamily: "Montserrat",
                      }}
                      key={head}
                      align={head === "Coin" ? "" : ""}
                    >
                      {head}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currencies &&
                  currencies.map((currency) => (
                    <TableRow key={currency?.currency} className={classes.row}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          display: "flex",
                          gap: 15,
                        }}
                      >
                        <img
                          src={currency?.image}
                          alt={currency?.currency}
                          height="50"
                          style={{ marginBottom: 10 }}
                        />
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              textTransform: "uppercase",
                              fontSize: 22,
                            }}
                          >
                            {currency?.currency}
                          </span>
                          <span style={{ color: "darkgrey" }}>
                            {currency?.description}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        LKR{" "}
                        {numberWithCommas(currency.buying)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        LKR{" "}
                        {numberWithCommas(currency.selling)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Container>
    </ThemeProvider>
  );
};

export default CurrencyTable;
