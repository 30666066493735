import React from "react";
import {
  AppBar,
  Button,
  Container,
  createTheme,
  Toolbar,
  Typography,
  ThemeProvider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
// import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  title: {
    flex: 1,
    fontWeight: "bold",
    fontFamily: "Roboto,Helvetica,Arial,  sans-serif",
    cursor: "pointer",
    fontSize: "21px",
  },
}));

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
    type: "dark",
  },
});
const Header = ({header}) => {
  const classes = useStyles();
  // const history = useHistory();

  return (
    <ThemeProvider>
      <AppBar color="transparent" position="static">
        <Container>
          <Toolbar>
            
              
            
              {header}
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};

export default Header;
