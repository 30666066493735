import React from "react";
import propTypes from "prop-types";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "20px",
    width: "100%",
    padding: "10px",
    display: "flex",
    border: "none",
    outline: "none",
    color:"white",
    fontSize: "19px",
    background: "#EDD94C",
    letterSpacing: "1px",
  },
  select: {
    borderColor: "green !important",
    borderRadius: "5px",
    '&:before': {
        borderColor:  "green !important",
    },
    '&:after': {
        borderColor:  "green !important",
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const CurrencyInput = (props) => {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const classes = useStyles();
  return (
    <div className={classes.input}>
      <FormControl variant="outlined" className={classes.formControl}>
        <TextField
          id="outlined-basic"
          label="Amount"
          type="number"
          color="primary"
          value={props.amount}
          variant="outlined"
          onChange={(event) => props.onAmountChange(event.target.value)}
        />
      </FormControl>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">currency</InputLabel>
        <Select
          labelId="demo-simple-select-label-outlined"
          id="demo-simple-select"
          value={props.currency}
          label="Currency"
          onChange={(event) => props.onCurrencyChange(event.target.value)}
          className={classes.select}
          disabled={props.disabled}
        >
          {props.currencies.map((currency) => (
            <MenuItem value={currency}>{currency}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

CurrencyInput.propTypes = {
  amount: propTypes.number.isRequired,
  currency: propTypes.string.isRequired,
  currencies: propTypes.array,
  onAmountChange: propTypes.func,
  onCurrencyChange: propTypes.func,
};

export default CurrencyInput;
