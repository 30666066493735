const API_URL =window.location.href.includes('bitleonex') ?   "https://fx.bitleonex.com/api" : "http://localhost:8000";

export const bannerCurrencies = () => `${API_URL}/currencies/banner`;

export const CurrencyList = (currency) =>
  `${API_URL}/markets?vs_currency=${currency}`;

export const SingleCurrency = (id) => `${API_URL}/coins/${id}`;

export const TrendingCoins = () => `${API_URL}/banner`;

export const CoinList = () => `${API_URL}/`;

export const Converter = () => `${API_URL}/converter`;

export const loginApi = () => `${API_URL}/login`;
